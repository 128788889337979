import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="React JS / Gatsby JS developer available for hire for remote contract work. Gatsby.js developer - UK based."
        />
        <title>
          UK based React &amp; Gatsby developer. Available for hire!
        </title>
      </Helmet>
      <div className="bg-white p-6 rounded-md mx-4">
        <h1 className="text-3xl font-heading mb-4 font-bold">
          React &amp; Gatsby Development
        </h1>
        <div className="font-light sm:flex">
          <div>
            <StaticImage
              alt="Me"
              height={200}
              placeholder="none"
              src="../images/p1.jpg"
            />
            <div className="text-slate-500 text-sm mt-2 font-normal">
              Location: Elan Valley, Wales
            </div>
          </div>
          <div className="flex-1 mt-4 sm:mt-0 sm:pl-4">
            <div>
              I've been developing front end web and mobile apps in the United
              Kingdom for over 10 years. Recently my focus has been developing
              websites using Gatsby JS &amp; TypeScript with various headless
              CMS's such as Prismic, Contentful, Headless WordPress, &amp;
              DatoCMS, to name a few. DatoCMS is by far my favourite headless
              CMS. I've created a few open source plugins for DatoCMS. My two
              most popular can be viewed here along with the repos:
              <div className="mt-2">
                <strong className="text-sm text-slate-500">
                  Font Awesome v5 icons:
                </strong>
                <div>
                  <a
                    href="https://www.datocms.com/marketplace/plugins/i/datocms-plugin-fontawesome"
                    className="text-sm text-blue-400"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    Plugin
                  </a>
                  <a
                    href="https://github.com/tomphill/datocms-plugin-fontawesome"
                    className="ml-2 text-sm text-blue-400"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    Repo
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <strong className="text-sm text-slate-500">
                  Responsive widths:
                </strong>
                <div>
                  <a
                    href="https://www.datocms.com/marketplace/plugins/i/datocms-plugin-responsive-widths"
                    className="text-sm text-blue-400"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    Plugin
                  </a>
                  <a
                    href="https://github.com/tomphill/datocms-plugin-responsive-widths"
                    className="ml-2 text-sm text-blue-400"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    Repo
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-2">
              As well as contracting for various large SAAS platforms mainly
              using React JS, Gatsby JS &amp; TypeScript, I'm now compiling and
              teaching a catalogue of courses on front end web development.
            </div>
          </div>
        </div>
        <h1 className="text-3xl font-heading mb-4 font-bold mt-8">
          Online Courses &amp; Tutorials
        </h1>
        <p className="font-light">
          Way back when I was a junior developer, I was fortunate enough to have
          been mentored by experts in the field. This allowed me to rapidly
          progress in my career and understand and architect multiple large
          projects in my time as an expert front end developer. While I'm still
          largely involved in contract work, I'm simultaneously passing on the
          buck and sharing my knowledge. Get the same expert mentoring that I
          received by enrolling on one of my React-based courses and fast-track
          your understanding and knowledge in front end web development. These
          courses are available on{" "}
          <a
            className="text-blue-400"
            href="https://webdeveducation.com"
            target="_blank"
            rel="nofollow noreferrer"
          >
            WebDevEducation
          </a>
          ,{" "}
          <a
            target="_blank"
            rel="nofollow noreferrer"
            className="text-blue-400"
            href="https://www.udemy.com/user/e3ccfcc3-dbaa-4ec2-a50f-fe761c2eb901/"
          >
            Udemy
          </a>
          , with free tutorials on{" "}
          <a
            target="_blank"
            rel="nofollow noreferrer"
            href="https://youtube.com/c/webdeveducation"
            className="text-blue-400"
          >
            YouTube
          </a>
          .
        </p>
        <h1 className="text-3xl font-heading mt-4 mb-4 font-bold">
          Featured Tutorial
        </h1>
        <h2 className="text-2xl font-heading mb-4 font-bold">
          Gatsby JS v4 &amp; DatoCMS Crash Course
        </h2>
        <div className="md:max-w-[65%] mx-auto">
          <div className="video-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/FznMKUJMLSo"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
